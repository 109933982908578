import { useActionCreators } from 'store/hooks';
import { setToken, setEvotorToken, isAuth } from 'store/slices/auth/authSlice';

import { MainPage } from 'pages';

const App = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const actions = useActionCreators({ setToken, setEvotorToken, isAuth });
  const evotorToken = searchParams.get('evotorToken');
  const token = searchParams.get('token');

  if (evotorToken && token) {
    actions.setToken(token);
    actions.setEvotorToken(evotorToken);
  }
  actions.isAuth();
  return <MainPage />;
};

export default App;
