import type { ColumnsType } from 'antd/es/table';
import type { TReportData } from 'types/types';

export const reportsColumns: ColumnsType<TReportData> = [
  {
    title: <span style={{ color: '#1677ff' }}>Наименование</span>,
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '35%',
  },
  {
    title: <span style={{ color: '#1677ff' }}>Код товара</span>,
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: '8%',
  },
  {
    title: <span style={{ color: '#1677ff' }}>Количество</span>,
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    width: '11%',
  },
  {
    title: <span style={{ color: '#1677ff' }}>Сумма</span>,
    dataIndex: 'sum',
    key: 'sum',
    align: 'center',
    width: '8%',
  },
  {
    title: <span style={{ color: '#1677ff' }}>Дата</span>,
    dataIndex: 'created',
    key: 'created',
    align: 'center',
    width: '8%',
  },
  {
    title: <span style={{ color: '#1677ff' }}>Номер заказа</span>,
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
    width: '30%',
  },
];
