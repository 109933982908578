import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import WaiterService from 'services/waiterService';

import {
  TAxiosErrorResponse,
  TDeleteWaiterRequest,
  TDeleteWaiterResponse,
  TEditWaiterRequest,
  TEditWaiterResponse,
  TGetWaitersRequest,
  TGetWaitersResponse,
  TPostWaiterRequest,
  TPostWaiterResponse,
  TRejectError,
} from 'types/types';

export const getWaiters = createAsyncThunk<TGetWaitersResponse, TGetWaitersRequest, TRejectError>(
  'waiter/getAllWaiters',
  async ({ token, shopId }, thunkApi) => {
    try {
      const response = await WaiterService.getWaiters(token, shopId);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const createWaiter = createAsyncThunk<TPostWaiterResponse, TPostWaiterRequest, TRejectError>(
  'waiter/createWaiter',
  async ({ token, body }, thunkApi) => {
    try {
      const response = await WaiterService.createWaiter(token, body);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const deleteWaiter = createAsyncThunk<
  TDeleteWaiterResponse,
  TDeleteWaiterRequest,
  TRejectError
>('waiter/deleteWaiter', async ({ token, waiterId }, thunkApi) => {
  try {
    const response = await WaiterService.deleteWaiter(token, waiterId);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorResponse: TAxiosErrorResponse | undefined = error.response;
      if (errorResponse) {
        const { data } = errorResponse;
        return thunkApi.rejectWithValue({
          message: data.message,
        });
      }
    }
    if (error instanceof Error) {
      return thunkApi.rejectWithValue({
        message: error?.message,
      });
    }
    return thunkApi.rejectWithValue({
      message: 'Unknown error occurred.',
    });
  }
});

export const editWaiter = createAsyncThunk<TEditWaiterResponse, TEditWaiterRequest, TRejectError>(
  'waiter/editWaiter',
  async ({ token, waiterId, body }, thunkApi) => {
    try {
      const response = await WaiterService.editWaiter(token, waiterId, body);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);
