import { Button, Typography } from 'antd';

import style from './CloseContetn.module.css';

const { Title, Text } = Typography;

export const CloseContent = () => (
  <div className={style.wrapper}>
    <Title level={2}>Ошибка авторизации</Title>
    <Text>Необходимо пройти авторизацию в личном кабинете Эвотор</Text>
    <Button href="https://evotor.ru/" size="large" type="primary">
      Авторизация
    </Button>
  </div>
);
