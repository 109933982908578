import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormInstance, List, Spin, Typography } from 'antd';

import { TActiveWaiterList, TSelectList, TWaiterInfoFormType } from 'types/types';

import EditForm from './EditForm';
import CreateForm from './CreateForm';

import style from './WaiterInfoUi.module.css';

const { Title, Text } = Typography;

type WaiterInfoUiPropsType = {
  createForm: FormInstance<TWaiterInfoFormType>;
  editForm: FormInstance<TWaiterInfoFormType>;
  storesData: TSelectList;
  employees: TSelectList;
  loading: boolean;
  storeSelectHandler: (id: string) => void;
  onCreateFinish: (formData: TWaiterInfoFormType) => void;
  onEditFinish: (formData: TWaiterInfoFormType) => void;
  deletehandler: (id: string) => void;
  edithandler: (id: string) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  waitersData: TActiveWaiterList;
  editMode: boolean;
};

const WaiterInfoUi = ({
  createForm,
  editForm,
  storesData,
  loading,
  onCreateFinish,
  onEditFinish,
  deletehandler,
  editMode,
  setEditMode,
  edithandler,
  waitersData,
  storeSelectHandler,
  employees,
}: WaiterInfoUiPropsType) => (
  <Spin spinning={loading} tip="Loading" size="large">
    <div className={style.wrapper}>
      {editMode ? (
        <EditForm
          form={editForm}
          storesData={storesData}
          onFinish={onEditFinish}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      ) : (
        <CreateForm
          form={createForm}
          storesData={storesData}
          onFinish={onCreateFinish}
          storeSelectHandler={storeSelectHandler}
          employees={employees}
        />
      )}
      <List
        className={style.list}
        header={
          <Title level={5} style={{ margin: 0, color: '#1677ff' }} ellipsis>
            Созданные официанты
          </Title>
        }
        locale={{ emptyText: 'Пока нет записей' }}
        bordered
        dataSource={waitersData}
        renderItem={(item) => (
          <List.Item
            actions={[
              <button
                type="button"
                className={style.delete_btn}
                onClick={() => deletehandler(item.value)}
              >
                <DeleteOutlined className={style.delete_icon} />
              </button>,
              <button
                type="button"
                className={style.edit_btn}
                onClick={() => edithandler(item.value)}
              >
                <EditOutlined className={style.edit_icon} />
              </button>,
            ]}
          >
            <List.Item.Meta
              title={item.waiterName}
              className={style.cardItem}
              description={
                <div className={style.listItem}>
                  <Text ellipsis>{`Maгазин : ${item.shop}`}</Text>
                  <Text ellipsis>{`Ссылка на чаевые : ${item.tipsLink}`}</Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  </Spin>
);

export default WaiterInfoUi;
