import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import TableService from 'services/tableService';

import {
  TRejectError,
  TAxiosErrorResponse,
  TGetTablesResponse,
  TGetTablesRequest,
  TPostTablesResponse,
  TPostTablesRequest,
  TDeleteTablesRequest,
  TDeleteTablesResponse,
  TEditTablesRequest,
  TEditTablesResponse,
} from 'types/types';

export const getTables = createAsyncThunk<TGetTablesResponse, TGetTablesRequest, TRejectError>(
  'table/getAllTable',
  async ({ token, shopId }, thunkApi) => {
    try {
      const response = await TableService.getTables(token, shopId);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const createTable = createAsyncThunk<TPostTablesResponse, TPostTablesRequest, TRejectError>(
  'table/createTable',
  async ({ token, body }, thunkApi) => {
    try {
      const response = await TableService.createTable(token, body);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const deleteTable = createAsyncThunk<
  TDeleteTablesResponse,
  TDeleteTablesRequest,
  TRejectError
>('table/deleteTable', async ({ token, tableId }, thunkApi) => {
  try {
    const response = await TableService.deleteTable(token, tableId);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorResponse: TAxiosErrorResponse | undefined = error.response;
      if (errorResponse) {
        const { data } = errorResponse;
        return thunkApi.rejectWithValue({
          message: data.message,
        });
      }
    }
    if (error instanceof Error) {
      return thunkApi.rejectWithValue({
        message: error?.message,
      });
    }
    return thunkApi.rejectWithValue({
      message: 'Unknown error occurred.',
    });
  }
});

export const editTable = createAsyncThunk<TEditTablesResponse, TEditTablesRequest, TRejectError>(
  'table/editTable',
  async ({ token, tableId, body }, thunkApi) => {
    try {
      const response = await TableService.editTable(token, tableId, body);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);
