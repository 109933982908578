import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormInstance, List, Spin, Typography } from 'antd';

import { TActiveTableList, TSelectList, TTableInfoFormType } from 'types/types';

import EditForm from './EditForm';
import CreateForm from './CreateForm';

import style from './TableInfoUi.module.css';

const { Title, Text } = Typography;

type TableInfoUiPropsType = {
  createForm: FormInstance<TTableInfoFormType>;
  editForm: FormInstance<TTableInfoFormType>;
  storesData: TSelectList;
  loading: boolean;
  onCreateFinish: (formData: TTableInfoFormType) => void;
  onEditFinish: (formData: TTableInfoFormType) => void;
  deletehandler: (id: string) => void;
  edithandler: (id: string) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  tableDta: TActiveTableList;
  editMode: boolean;
};

const TableInfoUi = ({
  createForm,
  editForm,
  storesData,
  loading,
  onCreateFinish,
  onEditFinish,
  deletehandler,
  editMode,
  setEditMode,
  edithandler,
  tableDta,
}: TableInfoUiPropsType) => (
  <Spin spinning={loading} tip="Loading" size="large">
    <div className={style.wrapper}>
      {editMode ? (
        <EditForm
          form={editForm}
          storesData={storesData}
          onFinish={onEditFinish}
          setEditMode={setEditMode}
        />
      ) : (
        <CreateForm form={createForm} storesData={storesData} onFinish={onCreateFinish} />
      )}
      <List
        className={style.list}
        header={
          <Title level={5} style={{ margin: 0, color: '#1677ff' }} ellipsis>
            Созданные столы
          </Title>
        }
        bordered
        locale={{ emptyText: 'Пока нет записей' }}
        dataSource={tableDta}
        renderItem={(item) => (
          <List.Item
            actions={[
              <button
                type="button"
                className={style.delete_btn}
                onClick={() => deletehandler(item.value)}
              >
                <DeleteOutlined className={style.delete_icon} />
              </button>,
              <button
                type="button"
                className={style.edit_btn}
                onClick={() => edithandler(item.value)}
              >
                <EditOutlined className={style.edit_icon} />
              </button>,
            ]}
          >
            <List.Item.Meta
              title={item.tableName}
              className={style.cardItem}
              description={
                <div className={style.listItem}>
                  <Text ellipsis>{`Maгазин : ${item.shop}`}</Text>
                  <Text ellipsis>{`ID Yandex : ${item.yandexTableId}`}</Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  </Spin>
);

export default TableInfoUi;
