import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type {
  TAxiosErrorResponse,
  TGetDownLoadReportsRequest,
  TGetDownLoadReportsResponse,
  TGetReportsRequest,
  TGetReportsResponse,
  TRejectError,
} from 'types/types';

import ReportsService from 'services/reportsService';

export const getReport = createAsyncThunk<TGetReportsResponse, TGetReportsRequest, TRejectError>(
  'reports/getReport',
  async ({ token, shopId, fromDate, toDate }, thunkApi) => {
    try {
      const response = await ReportsService.getReport(token, shopId, fromDate, toDate);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const downloadReport = createAsyncThunk<
  TGetDownLoadReportsResponse,
  TGetDownLoadReportsRequest,
  TRejectError
  // eslint-disable-next-line consistent-return
>('reports/download', async ({ token, shopId, fromDate, toDate }, thunkApi) => {
  try {
    const response = await ReportsService.downloadReport(token, shopId, fromDate, toDate);

    const file = new Blob([response.data]);
    const nowDate = Date.now();
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Дневная выручка_${nowDate}.xlsx`;
    document.body.append(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorResponse: TAxiosErrorResponse | undefined = error.response;
      if (errorResponse) {
        const { data } = errorResponse;
        return thunkApi.rejectWithValue({
          message: data.message,
        });
      }
    }
    if (error instanceof Error) {
      return thunkApi.rejectWithValue({
        message: error?.message,
      });
    }
    return thunkApi.rejectWithValue({
      message: 'Unknown error occurred.',
    });
  }
});
