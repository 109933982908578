import axios from 'axios';

const { REACT_APP_EVOTOR_URL } = window.__env__ || process.env;

const _evotorApi = axios.create({
  baseURL: REACT_APP_EVOTOR_URL,
  timeout: 10000,
});

export default _evotorApi;
