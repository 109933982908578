import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormInstance, List, Spin, Typography } from 'antd';

import { PosInfoFormType, TActivePosList, TSelectList } from 'types/types';

import EditForm from './EditForm';
import CreateForm from './CreateForm';

import style from './PosInfoUi.module.css';

const { Title, Text } = Typography;

type PosInfoUiPropsType = {
  createForm: FormInstance<PosInfoFormType>;
  editForm: FormInstance<PosInfoFormType>;
  storesData: TSelectList;
  devicesData: TSelectList;
  loading: boolean;
  onCreateFinish: (formData: PosInfoFormType) => void;
  onEditFinish: (formData: PosInfoFormType) => void;
  deleteHandler: (id: string) => void;
  editHandler: (id: string) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  posDta: TActivePosList;
  editMode: boolean;
  storeSelectHandler: (id: string) => void;
};

const PosInfoUi = ({
  createForm,
  editForm,
  storesData,
  loading,
  onCreateFinish,
  onEditFinish,
  devicesData,
  deleteHandler,
  editMode,
  setEditMode,
  editHandler,
  storeSelectHandler,
  posDta,
}: PosInfoUiPropsType) => (
  <Spin spinning={loading} tip="Loading" size="large">
    <div className={style.wrapper}>
      {editMode ? (
        <EditForm
          form={editForm}
          storesData={storesData}
          devicesData={devicesData}
          onFinish={onEditFinish}
          setEditMode={setEditMode}
          editMode={editMode}
          storeSelectHandler={storeSelectHandler}
        />
      ) : (
        <CreateForm
          form={createForm}
          storesData={storesData}
          devicesData={devicesData}
          onFinish={onCreateFinish}
          storeSelectHandler={storeSelectHandler}
        />
      )}
      <List
        className={style.list}
        header={
          <Title level={5} style={{ margin: 0, color: '#1677ff' }} ellipsis>
            Подключенные кассовые аппараты
          </Title>
        }
        locale={{ emptyText: 'Пока нет записей' }}
        bordered
        dataSource={posDta}
        renderItem={(item) => (
          <List.Item
            actions={[
              <button
                type="button"
                className={style.delete_btn}
                onClick={() => deleteHandler(item.posId)}
              >
                <DeleteOutlined className={style.delete_icon} />
              </button>,
              <button
                type="button"
                className={style.edit_btn}
                onClick={() => editHandler(item.posId)}
              >
                <EditOutlined className={style.edit_icon} />
              </button>,
            ]}
          >
            <List.Item.Meta
              title={item.shop}
              className={style.cardItem}
              description={
                <div className={style.listItem}>
                  <Text ellipsis>{`Касса : ${item.pos}`}</Text>
                  <Text ellipsis>{`ID Yandex : ${item.id}`}</Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  </Spin>
);

export default PosInfoUi;
