import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import PosService from 'services/posService';

import {
  TRejectError,
  TGetPosResponse,
  TGetPosRequest,
  TAxiosErrorResponse,
  TPostPosRequest,
  TPostPosResponse,
  TDeletePosRequest,
  TDeletePosResponse,
  TEditPosRequest,
  TEditPosResponse,
} from 'types/types';

export const getPos = createAsyncThunk<TGetPosResponse, TGetPosRequest, TRejectError>(
  'pos/getAllPos',
  async ({ token }, thunkApi) => {
    try {
      const response = await PosService.getPos(token);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const createPos = createAsyncThunk<TPostPosResponse, TPostPosRequest, TRejectError>(
  'pos/createPos',
  async ({ token, body }, thunkApi) => {
    try {
      const response = await PosService.createPos(token, body);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const deletePos = createAsyncThunk<TDeletePosResponse, TDeletePosRequest, TRejectError>(
  'pos/deletePos',
  async ({ token, posId }, thunkApi) => {
    try {
      const response = await PosService.deletePos(token, posId);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const editPos = createAsyncThunk<TEditPosResponse, TEditPosRequest, TRejectError>(
  'pos/editPos',
  async ({ token, posId, body }, thunkApi) => {
    try {
      const response = await PosService.editPos(token, posId, body);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: TAxiosErrorResponse | undefined = error.response;
        if (errorResponse) {
          const { data } = errorResponse;
          return thunkApi.rejectWithValue({
            message: data.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);
