import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import EvotorService from 'services/evotorService';

import { TDevicesResponse, TEmployeesResponse, TRejectError, TStoresResponse } from 'types/types';

export const getStores = createAsyncThunk<TStoresResponse, string, TRejectError>(
  'evotor/getAllStores',
  async (token, thunkApi) => {
    try {
      const response = await EvotorService.getStores(token);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.message) {
          return thunkApi.rejectWithValue({
            message: error?.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const getEmployees = createAsyncThunk<TEmployeesResponse, string, TRejectError>(
  'evotor/getAllEmployees',
  async (token, thunkApi) => {
    try {
      const response = await EvotorService.getEmployees(token);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.message) {
          return thunkApi.rejectWithValue({
            message: error?.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);

export const getDevices = createAsyncThunk<TDevicesResponse, string, TRejectError>(
  'evotor/getAllDevices',
  async (token, thunkApi) => {
    try {
      const response = await EvotorService.getDevices(token);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.message) {
          return thunkApi.rejectWithValue({
            message: error?.message,
          });
        }
      }
      if (error instanceof Error) {
        return thunkApi.rejectWithValue({
          message: error?.message,
        });
      }
      return thunkApi.rejectWithValue({
        message: 'Unknown error occurred.',
      });
    }
  },
);
