import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

export interface AuthState {
  token: string;
  evotorToken: string;
  isAuth: boolean;
}

const initialState: AuthState = {
  token: '',
  evotorToken: '',
  isAuth: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    setEvotorToken: (state, action: PayloadAction<string>) => ({
      ...state,
      evotorToken: action.payload,
    }),
    isAuth: (state) => ({
      ...state,
      isAuth: !!state.token && !!state.evotorToken,
    }),
    resetToInit: () => initialState,
  },
});

export const authSelector = (state: RootState) => state.auth;

export const getAuthInfo = createSelector(authSelector, (info) => info);

export const { resetToInit, setToken, setEvotorToken, isAuth } = authSlice.actions;

export default authSlice.reducer;
