import { EntityState, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { isRejectError } from 'types/guards';
import { ITable, TErrorType } from 'types/types';

import { createTable, deleteTable, editTable, getTables } from './actions';

interface TableState {
  tables: EntityState<ITable>;
  error: TErrorType | null;
  isLoading: boolean;
  deleteMessage: string | null;
  isUpdated: boolean;
  isCreated: boolean;
  errorInfo?: any;
}

export const tableAdapter = createEntityAdapter<ITable>({
  selectId: (table) => table.tableId,
  sortComparer: (a, b) => a.tableId.localeCompare(b.tableId),
});
const initialState: TableState = {
  tables: tableAdapter.getInitialState(),
  error: null,
  isLoading: false,
  deleteMessage: null,
  isUpdated: false,
  isCreated: false,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    resetDeleteMessage: (state) => ({ ...state, deleteMessage: null }),
    resetErrors: (state) => ({ ...state, error: null }),
    resetIsCreated: (state) => ({ ...state, isCreated: false }),
    resetIsUpdated: (state) => ({ ...state, isUpdated: false }),
  },
  extraReducers: (builder) => {
    // get
    builder
      .addCase(getTables.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(getTables.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        tableAdapter.setAll(state.tables, payload.data);
      })
      .addCase(getTables.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: payload.message, create: '', update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // create
    builder
      .addCase(createTable.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(createTable.fulfilled, (state, { payload }) => {
        tableAdapter.setOne(state.tables, payload);
        state.isLoading = false;
        state.isCreated = true;
      })
      .addCase(createTable.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: payload.message, update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // delete
    builder
      .addCase(deleteTable.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        deleteMessage: null,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(deleteTable.fulfilled, (state, action) => {
        const deletedId = action.meta.arg.tableId;
        tableAdapter.removeOne(state.tables, deletedId);
        state.isLoading = false;
        state.deleteMessage = action.payload.message;
      })
      .addCase(deleteTable.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.isLoading = false;
          state.error = { get: '', create: '', update: '', delete: payload.message };
          return;
        }
        state.errorInfo = payload;
      });
    // edite
    builder
      .addCase(editTable.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(editTable.fulfilled, (state, action) => {
        state.isLoading = false;
        tableAdapter.upsertOne(state.tables, action.payload);
        state.isUpdated = true;
        return state;
      })
      .addCase(editTable.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: '', update: payload.message, delete: '' };
          return;
        }
        state.errorInfo = payload;
        state.isUpdated = false;
      });
  },
});

export const { resetDeleteMessage, resetErrors, resetIsCreated, resetIsUpdated } =
  tableSlice.actions;

export const rootTableSelector = (state: RootState) => state.table;

export const tablesSelector = createSelector(rootTableSelector, (tableState) =>
  tableAdapter.getSelectors().selectAll(tableState.tables),
);
export const getTablesLoading = (state: RootState) => state.table.isLoading;
export const getUpdateMarker = (state: RootState) => state.table.isUpdated;
export const getTableError = (state: RootState) => state.table.error;
export const getCreateMarker = (state: RootState) => state.table.isCreated;
export const getTableDeleteMessage = (state: RootState) => state.table.deleteMessage;

export default tableSlice.reducer;
