import { Button, Form, FormInstance, Input, Select } from 'antd';

import { TSelectList, TTableInfoFormType } from 'types/types';

import style from './CreateForm.module.css';

type TableInfoUiPropsType = {
  form: FormInstance<TTableInfoFormType>;
  storesData: TSelectList;
  onFinish: (formData: TTableInfoFormType) => void;
};

const CreateForm = ({ form, storesData, onFinish }: TableInfoUiPropsType) => (
  <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
    <Form.Item
      name="storeId"
      label="Магазин"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={storesData} />
    </Form.Item>
    <Form.Item
      name="nameTable"
      label="Имя стола"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>
    <Form.Item
      name="yandexTableId"
      label="ID Яндекс"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>
    <div className={style.btn_group}>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" htmlType="submit">
          Создать
        </Button>
      </Form.Item>
    </div>
  </Form>
);

export default CreateForm;
