import React from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import { TSelectList, TWaiterInfoFormType } from 'types/types';

import style from './EditForm.module.css';

type WaiterInfoUiPropsType = {
  form: FormInstance<TWaiterInfoFormType>;
  storesData: TSelectList;
  onFinish: (formData: TWaiterInfoFormType) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
};

const EditForm = ({ form, storesData, onFinish, setEditMode, editMode }: WaiterInfoUiPropsType) => (
  <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
    <Form.Item
      name="storeId"
      label="Магазин"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={storesData} disabled={editMode} />
    </Form.Item>
    <Form.Item
      name="waiterId"
      label="Официант"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" disabled={editMode} />
    </Form.Item>
    <Form.Item
      name="tipsLink"
      label="Ссылка на чаевые"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>

    <div className={style.btn_group}>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" onClick={() => setEditMode(false)}>
          Oтменить
        </Button>
      </Form.Item>
    </div>
  </Form>
);

export default EditForm;
