import { AxiosResponse } from 'axios';

import {
  TDeleteWaiterRequest,
  TDeleteWaiterResponse,
  TEditWaiterRequest,
  TEditWaiterResponse,
  TGetWaitersRequest,
  TGetWaitersResponse,
  TPostWaiterRequest,
  TPostWaiterResponse,
  TWaiterPostBody,
  TWaiterPutBody,
} from 'types/types';

import _api from '../http';

class WaiterService {
  static point = '/api/v1/lk/merchant/waiter';

  static async getWaiters(
    token: string,
    shopId: string,
  ): Promise<AxiosResponse<TGetWaitersResponse, TGetWaitersRequest>> {
    return _api.get<TGetWaitersResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { shopId },
    });
  }

  static async createWaiter(
    token: string,
    body: TWaiterPostBody,
  ): Promise<AxiosResponse<TPostWaiterResponse, TPostWaiterRequest>> {
    return _api.post<TPostWaiterResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async editWaiter(
    token: string,
    waiterId: string,
    body: TWaiterPutBody,
  ): Promise<AxiosResponse<TEditWaiterResponse, TEditWaiterRequest>> {
    return _api.put<TEditWaiterResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { waiterId },
    });
  }

  static async deleteWaiter(
    token: string,
    waiterId: string,
  ): Promise<AxiosResponse<TDeleteWaiterResponse, TDeleteWaiterRequest>> {
    return _api.delete<TDeleteWaiterResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { waiterId },
    });
  }
}

export default WaiterService;
