import { AxiosResponse } from 'axios';

import _evotorApi from 'http/evotorApi';

import { TStoresResponse, TEmployeesResponse, TDevicesResponse } from 'types/types';

class EvotorService {
  static async getStores(token: string): Promise<AxiosResponse<TStoresResponse>> {
    return _evotorApi.get<TStoresResponse>('/stores', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getEmployees(token: string): Promise<AxiosResponse<TEmployeesResponse>> {
    return _evotorApi.get<TEmployeesResponse>('/employees', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getDevices(token: string): Promise<AxiosResponse<TDevicesResponse>> {
    return _evotorApi.get<TDevicesResponse>('/devices', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default EvotorService;
