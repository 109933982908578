import React from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';

import { TSelectList, TTableInfoFormType } from 'types/types';

import style from './EditForm.module.css';

type TableInfoUiPropsType = {
  form: FormInstance<TTableInfoFormType>;
  storesData: TSelectList;
  onFinish: (formData: TTableInfoFormType) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditForm = ({ form, storesData, onFinish, setEditMode }: TableInfoUiPropsType) => (
  <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
    <Form.Item
      name="storeId"
      label="Магазин"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={storesData} />
    </Form.Item>
    <Form.Item
      name="nameTable"
      label="Имя стола"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>
    <Form.Item
      name="yandexTableId"
      label="ID Яндекс"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" autoComplete="off" />
    </Form.Item>
    <div className={style.btn_group}>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" onClick={() => setEditMode(false)}>
          Oтменить
        </Button>
      </Form.Item>
    </div>
  </Form>
);

export default EditForm;
