import { useEffect, useState } from 'react';
import { Tabs, Typography } from 'antd';

import { useActionCreators, useAppSelector } from 'store/hooks';
import { getEmployees, getStores, getDevices } from 'store/slices/evotor/actions';
import { getPos } from 'store/slices/pos/actions';
import { getAuthInfo } from 'store/slices/auth/authSlice';

import * as Components from 'components';

import { ETabs } from 'types/enums';
import style from './MainPage.module.css';

const { Title, Text } = Typography;

export const MainPage = () => {
  const [activeTab, setActiveTab] = useState(ETabs.CASH_REGISTERS);
  const { isAuth, evotorToken, token } = useAppSelector(getAuthInfo);
  const actions = useActionCreators({ getStores, getPos, getEmployees, getDevices });

  useEffect(() => {
    if (evotorToken) {
      actions.getStores(evotorToken);
      actions.getEmployees(evotorToken);
      actions.getDevices(evotorToken);
    }
  }, [evotorToken]);

  const handleTabChange = (key: string) => {
    setActiveTab(key as ETabs);
  };

  return (
    <div className={style.page_wrapper}>
      {isAuth ? (
        <>
          <Title className={style.title} level={4}>
            Настройка ЯндексЧек
          </Title>
          <Text className={style.description}>
            Данное приложение представляет собой интеграцию сервиса ЯндексЧек с нашим сервисом
            Эвотор. Обеспечивает удобный и простой способ связи между двумя сервисами, позволяя
            пользователям осуществлять оплату через терминалы, используя функциональность ЯндексЧек.
          </Text>
          <Tabs
            size="large"
            type="line"
            className={style.tab_wrapper}
            onChange={handleTabChange}
            activeKey={activeTab}
            items={[
              {
                key: ETabs.CASH_REGISTERS,
                label: 'Кассовые аппараты',
                children: <Components.PosInfo token={token} />,
              },
              {
                key: ETabs.TABLES,
                label: 'Столы',
                children: <Components.TableInfo token={token} />,
              },
              {
                key: ETabs.WAITERS,
                label: 'Официанты',
                children: <Components.WaiterInfo token={token} />,
              },
              {
                key: ETabs.SALE_REPORTS,
                label: 'Отчёт по продажам',
                children: <Components.SaleReports token={token} activeTab={activeTab} />,
              },
            ]}
          />
        </>
      ) : (
        <Components.CloseContent />
      )}
    </div>
  );
};
