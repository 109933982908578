import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { getEmployees, getStores, getDevices } from 'store/slices/evotor/actions';

import { IEvoStore, IEmployee, IDevices } from 'types/types';
import { isRejectError } from 'types/guards';

interface EvotorState {
  stores: IEvoStore[];
  employees: IEmployee[];
  devices: IDevices[];
  isLoading: boolean;
  errorInfo: null | string;
}

const initialState: EvotorState = {
  stores: [],
  employees: [],
  devices: [],
  isLoading: false,
  errorInfo: null,
};

export const evotorSlice = createSlice({
  name: 'evotor',
  initialState,
  reducers: {
    resetEvotorStores: (state) => ({ ...state, stores: [] }),
    resetEvotorEmployees: (state) => ({ ...state, employees: [] }),
    resetEvotorError: (state) => ({ ...state, errorInfo: null }),
  },
  extraReducers: (builder) => {
    // stores
    builder
      .addCase(getStores.pending, (state) => ({
        ...state,
        errorInfo: null,
        isLoading: true,
      }))
      .addCase(getStores.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        stores: payload.items,
      }))
      .addCase(getStores.rejected, (state, { payload }) => {
        if (isRejectError(payload)) {
          return {
            ...state,
            isLoading: false,
            errorInfo: payload.message,
          };
        }
        return {
          ...state,
          isLoading: false,
          errorInfo: payload ?? null,
        };
      });
    // employees
    builder
      .addCase(getEmployees.pending, (state) => ({
        ...state,
        errorInfo: null,
        isLoading: true,
      }))
      .addCase(getEmployees.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        employees: payload.items,
      }))
      .addCase(getEmployees.rejected, (state, { payload }) => {
        if (isRejectError(payload)) {
          return {
            ...state,
            isLoading: false,
            errorInfo: payload.message,
          };
        }
        return {
          ...state,
          isLoading: false,
          errorInfo: payload ?? null,
        };
      });
    // devices
    builder
      .addCase(getDevices.pending, (state) => ({
        ...state,
        errorInfo: null,
        isLoading: true,
      }))
      .addCase(getDevices.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        devices: payload.items,
      }))
      .addCase(getDevices.rejected, (state, { payload }) => {
        if (isRejectError(payload)) {
          return {
            ...state,
            isLoading: false,
            errorInfo: payload.message,
          };
        }
        return {
          ...state,
          isLoading: false,
          errorInfo: payload ?? null,
        };
      });
  },
});

export const rootSelector = (state: RootState) => state.evotor;
export const getEvotorInfo = createSelector(rootSelector, (state) => state);
export const getEvotorStores = createSelector(rootSelector, (state) => state.stores);
export const getEvotorEmployees = createSelector(rootSelector, (state) => state.employees);
export const getEvotorDevices = createSelector(rootSelector, (state) => state.devices);

export const { resetEvotorStores, resetEvotorEmployees, resetEvotorError } = evotorSlice.actions;

export default evotorSlice.reducer;
