import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';

import { getEvotorDevices, getEvotorInfo, getEvotorStores } from 'store/slices/evotor/evotorSlice';
import { useActionCreators, useAppSelector } from 'store/hooks';
import { createPos, getPos, editPos, deletePos } from 'store/slices/pos/actions';
import {
  getCreateMarker,
  getPosDeleteMessage,
  getPosError,
  getUpdateMarker,
  posSelector,
  resetDeleteMessage,
  resetErrors,
  resetIsCreated,
  resetIsUpdated,
} from 'store/slices/pos/posSlice';

import { useNotifications } from 'hooks';
import {
  clearDependentFields,
  getDevicesByStore,
  getListPosData,
  getSelectListData,
  getUnconnectedList,
} from 'helpers';

import { IDevices, PosInfoFormType } from 'types/types';

import PosInfoUi from './PosInfoUi';

export const PosInfo = ({ token }: { token: string }) => {
  const [storeDevices, setStoreDevices] = useState<IDevices[] | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [createForm] = Form.useForm<PosInfoFormType>();
  const [editForm] = Form.useForm<PosInfoFormType>();
  const editPosId = useRef<string>('');

  const { isLoading } = useAppSelector(getEvotorInfo);
  const stores = useAppSelector(getEvotorStores);
  const error = useAppSelector(getPosError);
  const deleteMessage = useAppSelector(getPosDeleteMessage);
  const devices = useAppSelector(getEvotorDevices);
  const posDta = useAppSelector(posSelector);
  const createMarker = useAppSelector(getCreateMarker);
  const uiListData = getListPosData(posDta, devices, stores);
  const updateMarker = useAppSelector(getUpdateMarker);

  const actions = useActionCreators({
    deletePos,
    resetDeleteMessage,
    createPos,
    getPos,
    editPos,
    resetErrors,
    resetIsCreated,
    resetIsUpdated,
  });

  const deleteHandler = (id: string) => {
    actions.deletePos({
      token,
      posId: id,
    });
  };

  const storeSelectHandler = (id: string) => {
    // фильтрация касс по магазину
    const devicesByStore = getDevicesByStore(devices, id);
    // исключение подключенных касс
    const usefulDevices = getUnconnectedList(devicesByStore, posDta);
    setStoreDevices(usefulDevices);
    // очистка зависимых полей, при условии, что зависимое поле заполнено
    if (editMode ? editForm.getFieldValue('posId') : createForm.getFieldValue('posId')) {
      clearDependentFields(createForm, editForm, editMode, 'posId');
    }
  };

  const editHandler = (id: string) => {
    setEditMode(true);
    editPosId.current = id;
    const currentPos = posDta?.find((item) => item.posId === id);
    const currentDevice = devices.find((d) => d.id === currentPos?.posId);
    if (currentPos && currentDevice) {
      editForm.setFields([
        { name: 'posId', value: currentDevice.name, errors: [] },
        { name: 'storeId', value: currentPos.shopId, errors: [] },
        { name: 'yandexPosId', value: currentPos.yandexPosId, errors: [] },
      ]);
    }
  };

  const resetPos = () => {
    actions.resetErrors();
    actions.resetDeleteMessage();
    actions.resetIsCreated();
    actions.resetIsUpdated();
  };

  const onCreateFinish = async (formData: PosInfoFormType) => {
    const { storeId, yandexPosId, posId } = formData;
    const body = {
      shopId: storeId,
      yandexPosId,
      posId,
    };
    actions.createPos({ token, body });
    createForm.setFields([
      { name: 'posId', value: undefined, errors: [] },
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'yandexPosId', value: undefined, errors: [] },
    ]);
  };

  const onEditFinish = async (formData: PosInfoFormType) => {
    const { storeId, yandexPosId } = formData;
    const posId = editPosId.current;
    const body = {
      shopId: storeId,
      yandexPosId,
    };

    actions.editPos({ token, posId, body });
    editForm.setFields([
      { name: 'posId', value: undefined, errors: [] },
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'yandexPosId', value: undefined, errors: [] },
    ]);
    editPosId.current = '';
    setEditMode(false);
  };

  useEffect(() => {
    actions.getPos({ token });
    return () => {
      editPosId.current = '';
    };
  }, []);

  useNotifications(!!deleteMessage, !!error?.delete, deleteMessage || '', error?.delete, resetPos);
  useNotifications(
    !!createMarker,
    !!error?.create,
    'Операция прошла успешно' || '',
    error?.create,
    resetPos,
  );
  useNotifications(false, !!error?.get, '', error?.get, resetPos);
  useNotifications(
    !!updateMarker,
    !!error?.update,
    'Операция прошла успешно',
    error?.update,
    resetPos,
  );
  return (
    <PosInfoUi
      createForm={createForm}
      editForm={editForm}
      storesData={getSelectListData(stores)}
      devicesData={getSelectListData(storeDevices)}
      loading={isLoading}
      onCreateFinish={onCreateFinish}
      onEditFinish={onEditFinish}
      deleteHandler={deleteHandler}
      editHandler={editHandler}
      posDta={uiListData}
      editMode={editMode}
      setEditMode={setEditMode}
      storeSelectHandler={storeSelectHandler}
    />
  );
};
