import { FormInstance } from 'antd';

import { isPosEntity } from 'types/guards';
import {
  IDevices,
  IEmployee,
  IEvoStore,
  IPos,
  ITable,
  IWaiter,
  PosInfoFormType,
  TGetReportsResponse,
  TWaiterInfoFormType,
} from 'types/types';

export const getSelectListData = (arr: { id: string; name: string }[] | null) =>
  arr?.length ? arr.map((item) => ({ value: item.id, label: item.name })) : [];

export const getListPosData = (arr: IPos[] | null, devices: IDevices[], stores: IEvoStore[]) => {
  // словари для поиска имён по id
  const devicesDirectory: Record<string, string> = {};
  const storesDirectory: Record<string, string> = {};
  // наполнение словарей
  devices.forEach((item) => (devicesDirectory[item.id] = item.name));
  stores.forEach((item) => (storesDirectory[item.id] = item.name));

  return arr?.length
    ? arr.map((pos) => ({
        value: pos.yandexPosId,
        pos: devicesDirectory[pos.posId] || 'КАССА',
        shop: storesDirectory[pos.shopId] || 'МАГАЗИН',
        id: pos.yandexPosId,
        posId: pos.posId,
      }))
    : [];
};

export const getTableData = (arr: ITable[] | null, stores: IEvoStore[]) => {
  // словари для поиска имён по id
  const storesDirectory: Record<string, string> = {};
  // наполнение словарей
  stores.forEach((item) => (storesDirectory[item.id] = item.name));

  return arr?.length
    ? arr.map((table) => ({
        value: table.tableId,
        tableName: table.name,
        shop: storesDirectory[table.shopId] || 'МАГАЗИН',
        yandexTableId: table.yandexTableId,
      }))
    : [];
};

export const getWaiterData = (arr: IWaiter[] | null, stores: IEvoStore[]) => {
  // словари для поиска имён по id
  const storesDirectory: Record<string, string> = {};
  // наполнение словарей
  stores.forEach((item) => (storesDirectory[item.id] = item.name));

  return arr?.length
    ? arr.map((waiter) => ({
        value: waiter.waiterId,
        waiterName: waiter.name,
        shop: storesDirectory[waiter.shopId] || 'МАГАЗИН',
        tipsLink: waiter.tipsLink,
      }))
    : [];
};

export const getDevicesByStore = (arr: IDevices[], storeId: string) => {
  const devicesFiltered = arr.filter((item) => item.store_id === storeId);
  return devicesFiltered?.length ? devicesFiltered : [];
};

export const getEmpolyeesByStore = (arr: IEmployee[], storeId: string) => {
  const employeesFiltered = arr.filter((item) => item.stores.includes(storeId));
  return employeesFiltered?.length ? employeesFiltered : [];
};

export const getUnconnectedList = <T extends IDevices | IEmployee>(
  allEntyties: T[],
  connectedList: (IWaiter | IPos)[],
) => {
  const alreadСonnectedIds: string[] = connectedList.map((item) => {
    if (isPosEntity(item)) {
      return item.posId;
    }
    return item.waiterId;
  });

  return alreadСonnectedIds?.length
    ? allEntyties.filter((item) => !alreadСonnectedIds.includes(item.id))
    : allEntyties;
};

export const clearDependentFields = <T extends PosInfoFormType | TWaiterInfoFormType>(
  createForm: FormInstance<T>,
  editForm: FormInstance<T>,
  isEdit: boolean,
  field: T extends PosInfoFormType ? keyof PosInfoFormType : keyof TWaiterInfoFormType,
) => {
  if (isEdit) {
    editForm.setFields([{ name: field, value: undefined, errors: [] }]);
  } else {
    createForm.setFields([{ name: field, value: undefined, errors: [] }]);
  }
};

export const generateUniqueKey = () =>
  Date.now().toString(36) + Math.random().toString(36).substr(2);

export const getReportsWithKey = (data: TGetReportsResponse) => {
  const report = [...data.data];
  const reportsWithKey = report.map((item) => ({
    ...item,
    key: generateUniqueKey(),
  }));

  return {
    data: reportsWithKey,
    total: data.total,
  };
};
