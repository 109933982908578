import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { isRejectError } from 'types/guards';
import type { TGetReportsResponse } from 'types/types';

import { getReportsWithKey } from 'helpers';

import { downloadReport, getReport } from './actions';

interface ReportsState {
  report: TGetReportsResponse;
  error: string | null;
  isLoading: boolean;
}

const initialState: ReportsState = {
  report: { data: [], total: 0 },
  error: null,
  isLoading: false,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetReports: () => initialState,
    resetErrors: (state) => ({ ...state, error: null }),
    setReportError: (state, { payload }) => ({ ...state, error: payload }),
  },
  extraReducers: (builder) => {
    // get report
    builder
      .addCase(getReport.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
      }))
      .addCase(getReport.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        state.report = getReportsWithKey(payload);
      })
      .addCase(getReport.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = payload.message;
        }
        state.error = payload?.message ?? null;
      });
    // download report
    builder
      .addCase(downloadReport.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
      }))
      .addCase(downloadReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(downloadReport.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = payload.message;
        }
        state.error = payload?.message ?? null;
      });
  },
});

export const { resetReports, resetErrors, setReportError } = reportsSlice.actions;

export const rootReportsSelector = (state: RootState) => state.reports;

export const getReportsSelector = createSelector(
  rootReportsSelector,
  (reportsState) => reportsState.report,
);

export const getReportsLoading = (state: RootState) => state.reports.isLoading;
export const getReportsError = (state: RootState) => state.reports.error;

export default reportsSlice.reducer;
