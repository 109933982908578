import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import ruRU from 'antd/lib/locale/ru_RU';
import ru from 'dayjs/locale/ru';

import { store } from 'store/store';

import App from './App';

import './index.css';

dayjs.locale(ru);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={ruRU}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  //  </React.StrictMode>,
);
