import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';

import { createTable, deleteTable, editTable, getTables } from 'store/slices/table/actions';
import { getEvotorStores } from 'store/slices/evotor/evotorSlice';
import { useActionCreators, useAppSelector } from 'store/hooks';
import {
  getTableDeleteMessage,
  getTableError,
  tablesSelector,
  getCreateMarker,
  getUpdateMarker,
  resetErrors,
  resetIsCreated,
  resetDeleteMessage,
  resetIsUpdated,
  getTablesLoading,
} from 'store/slices/table/tableSlice';

import { useNotifications } from 'hooks';
import { getSelectListData, getTableData } from 'helpers';

import { TTableInfoFormType } from 'types/types';

import TableInfoUi from './TableInfoUi';

export const TableInfo = ({ token }: { token: string }) => {
  const [editMode, setEditMode] = useState(false);
  const [createForm] = Form.useForm<TTableInfoFormType>();
  const [editForm] = Form.useForm<TTableInfoFormType>();
  const editTableId = useRef<string>('');

  const isLoading = useAppSelector(getTablesLoading);
  const stores = useAppSelector(getEvotorStores);
  const error = useAppSelector(getTableError);
  const deleteMessage = useAppSelector(getTableDeleteMessage);
  const tablesData = useAppSelector(tablesSelector);
  const updateMarker = useAppSelector(getUpdateMarker);
  const createMarker = useAppSelector(getCreateMarker);
  const uiListData = getTableData(tablesData, stores);

  const actions = useActionCreators({
    createTable,
    getTables,
    editTable,
    deleteTable,
    resetErrors,
    resetIsCreated,
    resetDeleteMessage,
    resetIsUpdated,
  });

  const deletehandler = (id: string) => {
    actions.deleteTable({
      token,
      tableId: id,
    });
  };

  const edithandler = (id: string) => {
    setEditMode(true);
    editTableId.current = id;
    const currentTable = tablesData?.find((item) => item.tableId === id);
    if (currentTable) {
      editForm.setFields([
        { name: 'nameTable', value: currentTable.name, errors: [] },
        { name: 'storeId', value: currentTable.shopId, errors: [] },
        { name: 'yandexTableId', value: currentTable.yandexTableId, errors: [] },
      ]);
    }
  };

  const resetTable = () => {
    actions.resetErrors();
    actions.resetDeleteMessage();
    actions.resetIsCreated();
    actions.resetIsUpdated();
  };

  const onCreateFinish = async (formData: TTableInfoFormType) => {
    const { storeId, yandexTableId, nameTable } = formData;
    const body = {
      name: nameTable,
      yandexTableId,
      shopId: storeId,
    };
    actions.createTable({ token, body });
    createForm.setFields([
      { name: 'nameTable', value: undefined, errors: [] },
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'yandexTableId', value: undefined, errors: [] },
    ]);
  };

  const onEditFinish = async (formData: TTableInfoFormType) => {
    const { storeId, yandexTableId, nameTable } = formData;
    const body = {
      name: nameTable,
      yandexTableId,
      shopId: storeId,
    };
    actions.editTable({ token, tableId: editTableId.current, body });
    editForm.setFields([
      { name: 'nameTable', value: undefined, errors: [] },
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'yandexTableId', value: undefined, errors: [] },
    ]);
    editTableId.current = '';
    setEditMode(false);
  };

  useEffect(() => {
    actions.getTables({ token, shopId: '' });
  }, []);

  useNotifications(
    !!deleteMessage,
    !!error?.delete,
    deleteMessage || 'Успешно удалено',
    error?.delete,
    resetTable,
  );
  useNotifications(
    !!createMarker,
    !!error?.create,
    'Операция прошла успешно',
    error?.create,
    resetTable,
  );
  useNotifications(
    !!updateMarker,
    !!error?.update,
    'Операция прошла успешно',
    error?.update,
    resetTable,
  );
  useNotifications(false, !!error?.get, '', error?.get, resetTable);

  return (
    <TableInfoUi
      createForm={createForm}
      editForm={editForm}
      storesData={getSelectListData(stores)}
      loading={isLoading}
      onCreateFinish={onCreateFinish}
      onEditFinish={onEditFinish}
      deletehandler={deletehandler}
      edithandler={edithandler}
      tableDta={uiListData}
      editMode={editMode}
      setEditMode={setEditMode}
    />
  );
};
