import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsMapObject, AsyncThunk, bindActionCreators } from '@reduxjs/toolkit';

import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type TBoundAsyncThunk<Thunk extends AsyncThunk<any, any, any>> = (
  ...args: Parameters<Thunk>
) => ReturnType<ReturnType<Thunk>>;

type TBoundActions<TActions extends ActionCreatorsMapObject> = {
  [key in keyof TActions]: TActions[key] extends AsyncThunk<any, any, any>
    ? TBoundAsyncThunk<TActions[key]>
    : TActions[key];
};

export const useActionCreators = <TActions extends ActionCreatorsMapObject>(
  actions: TActions,
): TBoundActions<TActions> => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), []);
};
