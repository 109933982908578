import { Button, Form, FormInstance, Input, Select } from 'antd';

import { TSelectList, TWaiterInfoFormType } from 'types/types';

import style from './CreateForm.module.css';

type WaiterInfoUiPropsType = {
  form: FormInstance<TWaiterInfoFormType>;
  storesData: TSelectList;
  onFinish: (formData: TWaiterInfoFormType) => void;
  storeSelectHandler: (id: string) => void;
  employees: TSelectList;
};

const CreateForm = ({
  form,
  storesData,
  onFinish,
  storeSelectHandler,
  employees,
}: WaiterInfoUiPropsType) => (
  <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
    <Form.Item
      name="storeId"
      label="Магазин"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={storesData} onChange={storeSelectHandler} />
    </Form.Item>
    <Form.Item
      name="waiterId"
      label="Официант"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={employees} disabled={!employees?.length} />
    </Form.Item>
    <Form.Item
      name="tipsLink"
      label="Ссылка на чаевые"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>

    <div className={style.btn_group}>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" htmlType="submit">
          Создать
        </Button>
      </Form.Item>
    </div>
  </Form>
);

export default CreateForm;
