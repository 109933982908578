import { useEffect } from 'react';
import { Form } from 'antd';
import dayjs from 'dayjs';

import { getEvotorStores } from 'store/slices/evotor/evotorSlice';
import { useActionCreators, useAppSelector } from 'store/hooks';
import { downloadReport, getReport } from 'store/slices/reports/actions';

import {
  getReportsError,
  getReportsLoading,
  getReportsSelector,
  resetErrors,
  resetReports,
  setReportError,
} from 'store/slices/reports/reportsSlice';

import { useNotifications } from 'hooks';

import { getSelectListData } from 'helpers';

import type { TReportsForm } from 'types/types';

import { ETabs } from 'types/enums';

import SaleReportsUi from './SaleReportsUi';

export const SaleReports = ({ token, activeTab }: { token: string; activeTab: string }) => {
  const [form] = Form.useForm();

  const isLoading = useAppSelector(getReportsLoading);
  const reportsData = useAppSelector(getReportsSelector);
  const error = useAppSelector(getReportsError);
  const stores = useAppSelector(getEvotorStores);

  const actions = useActionCreators({
    getReport,
    downloadReport,
    resetErrors,
    resetReports,
    setReportError,
  });

  const downloadReportByExcel = async () => {
    try {
      const {
        storeId,
        reportRange: [fromDate, toDate],
      } = await form.validateFields();

      actions.downloadReport({
        token,
        shopId: storeId,
        fromDate,
        toDate,
      });
    } catch (error) {
      actions.setReportError('Пожалуйста заполните обязательные поля!');
    }
  };

  const onChangeRangePicker = (_: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    form.setFields([
      {
        name: 'reportRange',
        value: [
          dayjs(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          dayjs(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ],
        errors: [],
      },
    ]);
  };

  const onFinish = async (formData: TReportsForm) => {
    const {
      storeId,
      reportRange: [fromDate, toDate],
    } = formData;

    actions.getReport({
      token,
      shopId: storeId,
      fromDate,
      toDate,
    });
  };

  const reset = () => {
    actions.resetErrors();
  };

  useEffect(() => {
    if (activeTab !== ETabs.SALE_REPORTS) {
      form.resetFields();
      actions.resetReports();
    }
  }, [activeTab]);

  useNotifications(false, !!error, '', error, reset);

  return (
    <SaleReportsUi
      form={form}
      storesData={getSelectListData(stores)}
      loading={isLoading}
      onFinish={onFinish}
      tableData={reportsData}
      onChangeRangePicker={onChangeRangePicker}
      downloadReportByExcel={downloadReportByExcel}
    />
  );
};
