import { EntityState, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { isRejectError } from 'types/guards';
import { IWaiter, TErrorType } from 'types/types';

import { createWaiter, deleteWaiter, editWaiter, getWaiters } from './actions';

interface WaiterState {
  waiters: EntityState<IWaiter>;
  error: TErrorType | null;
  isLoading: boolean;
  deleteMessage: string | null;
  isUpdated: boolean;
  isCreated: boolean;
  errorInfo?: any;
}

export const waiterAdapter = createEntityAdapter<IWaiter>({
  selectId: (waiter) => waiter?.waiterId,
  sortComparer: (a, b) => a.waiterId.localeCompare(b.waiterId),
});
const initialState: WaiterState = {
  waiters: waiterAdapter.getInitialState(),
  error: null,
  isLoading: false,
  deleteMessage: null,
  isUpdated: false,
  isCreated: false,
};

export const waiterSlice = createSlice({
  name: 'waiter',
  initialState,
  reducers: {
    resetDeleteMessage: (state) => ({ ...state, deleteMessage: null }),
    resetErrors: (state) => ({ ...state, error: null }),
    resetIsCreated: (state) => ({ ...state, isCreated: false }),
    resetIsUpdated: (state) => ({ ...state, isUpdated: false }),
  },
  extraReducers: (builder) => {
    // get
    builder
      .addCase(getWaiters.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(getWaiters.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        waiterAdapter.setAll(state.waiters, payload.data);
      })
      .addCase(getWaiters.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: payload.message, create: '', update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // create
    builder
      .addCase(createWaiter.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(createWaiter.fulfilled, (state, { payload }) => {
        waiterAdapter.setOne(state.waiters, payload);
        state.isLoading = false;
        state.isCreated = true;
      })
      .addCase(createWaiter.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: payload.message, update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // delete
    builder
      .addCase(deleteWaiter.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        deleteMessage: null,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(deleteWaiter.fulfilled, (state, action) => {
        const deletedId = action.meta.arg.waiterId;
        waiterAdapter.removeOne(state.waiters, deletedId);
        state.isLoading = false;
        state.deleteMessage = action.payload.message;
      })
      .addCase(deleteWaiter.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.isLoading = false;
          state.error = { get: '', create: '', update: '', delete: payload.message };
          return;
        }
        state.errorInfo = payload;
      });
    // edite
    builder
      .addCase(editWaiter.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(editWaiter.fulfilled, (state, action) => {
        state.isLoading = false;
        waiterAdapter.upsertOne(state.waiters, action.payload);
        state.isUpdated = true;
        return state;
      })
      .addCase(editWaiter.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: '', update: payload.message, delete: '' };
          return;
        }
        state.errorInfo = payload;
        state.isUpdated = false;
      });
  },
});

export const { resetDeleteMessage, resetErrors, resetIsCreated, resetIsUpdated } =
  waiterSlice.actions;

export const rootWaiterSelector = (state: RootState) => state.waiter;

export const waitersSelector = createSelector(rootWaiterSelector, (waiterState) =>
  waiterAdapter.getSelectors().selectAll(waiterState.waiters),
);
export const getWaitersLoading = (state: RootState) => state.waiter.isLoading;
export const getUpdateMarker = (state: RootState) => state.waiter.isUpdated;
export const getWaiterError = (state: RootState) => state.waiter.error;
export const getCreateMarker = (state: RootState) => state.waiter.isCreated;
export const getWaiterDeleteMessage = (state: RootState) => state.waiter.deleteMessage;

export default waiterSlice.reducer;
