import { EntityState, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { IPos, TErrorType } from 'types/types';
import { isRejectError } from 'types/guards';

import { createPos, deletePos, editPos, getPos } from './actions';

interface PosState {
  pos: EntityState<IPos>;
  error: TErrorType | null;
  isLoading: boolean;
  deleteMessage: string | null;
  isUpdated: boolean;
  isCreated: boolean;
  errorInfo?: any;
}

export const posAdapter = createEntityAdapter<IPos>({
  selectId: (pos) => pos.posId,
  sortComparer: (a, b) => a.posId.localeCompare(b.posId),
});

const initialState: PosState = {
  pos: posAdapter.getInitialState(),
  error: null,
  isLoading: false,
  deleteMessage: null,
  isUpdated: false,
  isCreated: false,
};

export const posSlice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    resetDeleteMessage: (state) => ({ ...state, deleteMessage: null }),
    resetErrors: (state) => ({ ...state, error: null }),
    resetIsCreated: (state) => ({ ...state, isCreated: false }),
    resetIsUpdated: (state) => ({ ...state, isUpdated: false }),
  },
  extraReducers: (builder) => {
    // get
    builder
      .addCase(getPos.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(getPos.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        posAdapter.setAll(state.pos, payload.data);
      })
      .addCase(getPos.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: payload.message, create: '', update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // create
    builder
      .addCase(createPos.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(createPos.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isCreated = true;
        posAdapter.setOne(state.pos, payload);
      })
      .addCase(createPos.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: payload.message, update: '', delete: '' };
          return;
        }
        state.errorInfo = payload;
      });
    // delete
    builder
      .addCase(deletePos.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        deleteMessage: null,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(deletePos.fulfilled, (state, action) => {
        const deletedId = action.meta.arg.posId;
        posAdapter.removeOne(state.pos, deletedId);
        state.isLoading = false;
        state.deleteMessage = action.payload.message;
      })
      .addCase(deletePos.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.isLoading = false;
          state.error = { get: '', create: '', update: '', delete: payload.message };
          return;
        }
        state.errorInfo = payload;
      });
    // edite
    builder
      .addCase(editPos.pending, (state) => ({
        ...state,
        error: null,
        isLoading: true,
        isUpdated: false,
        isCreated: false,
      }))
      .addCase(editPos.fulfilled, (state, action) => {
        state.isLoading = false;
        posAdapter.upsertOne(state.pos, action.payload as IPos);
        state.isUpdated = true;
        return state;
      })
      .addCase(editPos.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (isRejectError(payload)) {
          state.error = { get: '', create: '', update: payload.message, delete: '' };
          return;
        }
        state.errorInfo = payload;
        state.isUpdated = false;
      });
  },
});

export const { resetDeleteMessage, resetErrors, resetIsCreated, resetIsUpdated } = posSlice.actions;

export const rootPosSelector = (state: RootState) => state.pos;

export const posSelector = createSelector(rootPosSelector, (posState) =>
  posAdapter.getSelectors().selectAll(posState.pos),
);

export const getPosLoading = (state: RootState) => state.pos.isLoading;
export const getUpdateMarker = (state: RootState) => state.pos.isUpdated;
export const getPosError = (state: RootState) => state.pos.error;
export const getCreateMarker = (state: RootState) => state.pos.isCreated;
export const getPosDeleteMessage = (state: RootState) => state.pos.deleteMessage;

export default posSlice.reducer;
