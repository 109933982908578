import { Button, Form, FormInstance, Input, Select } from 'antd';

import { PosInfoFormType, TSelectList } from 'types/types';

import style from './CreateForm.module.css';

type PosInfoUiPropsType = {
  form: FormInstance<PosInfoFormType>;
  storesData: TSelectList;
  devicesData: TSelectList;
  onFinish: (formData: PosInfoFormType) => void;
  storeSelectHandler: (id: string) => void;
};

const CreateForm = ({
  form,
  storesData,
  onFinish,
  devicesData,
  storeSelectHandler,
}: PosInfoUiPropsType) => (
  <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
    <Form.Item
      name="storeId"
      label="Магазин"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={storesData} onChange={storeSelectHandler} />
    </Form.Item>
    <Form.Item
      name="posId"
      label="Касса"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Select size="large" options={devicesData} disabled={!devicesData?.length} />
    </Form.Item>
    <Form.Item
      name="yandexPosId"
      label="ID Яндекс"
      rules={[{ required: true, message: 'Обязательное поле' }]}
    >
      <Input size="large" />
    </Form.Item>
    <div className={style.btn_group}>
      <Form.Item noStyle shouldUpdate>
        <Button size="large" type="primary" htmlType="submit">
          Создать
        </Button>
      </Form.Item>
    </div>
  </Form>
);

export default CreateForm;
