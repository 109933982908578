import { configureStore } from '@reduxjs/toolkit';

import authReducer from 'store/slices/auth/authSlice';
import evotorReducer from 'store/slices/evotor/evotorSlice';
import posReducer from 'store/slices/pos/posSlice';
import reportsReducer from 'store/slices/reports/reportsSlice';
import tableReducer from 'store/slices/table/tableSlice';
import waiterReducer from 'store/slices/waiter/waiterSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    evotor: evotorReducer,
    pos: posReducer,
    table: tableReducer,
    waiter: waiterReducer,
    reports: reportsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
