import axios from 'axios';

const { REACT_APP_BACKEND_URL } = window.__env__ || process.env;

const _api = axios.create({
  baseURL: REACT_APP_BACKEND_URL,
  timeout: 10000,
});

export default _api;
