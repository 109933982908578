import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';

import { getEvotorEmployees, getEvotorStores } from 'store/slices/evotor/evotorSlice';
import { useActionCreators, useAppSelector } from 'store/hooks';
import {
  getCreateMarker,
  getUpdateMarker,
  getWaiterDeleteMessage,
  getWaiterError,
  getWaitersLoading,
  resetDeleteMessage,
  resetErrors,
  resetIsCreated,
  resetIsUpdated,
  waitersSelector,
} from 'store/slices/waiter/waiterSlice';
import { createWaiter, deleteWaiter, getWaiters, editWaiter } from 'store/slices/waiter/actions';

import { useNotifications } from 'hooks';
import {
  clearDependentFields,
  getEmpolyeesByStore,
  getSelectListData,
  getUnconnectedList,
  getWaiterData,
} from 'helpers';
import { IEmployee, TWaiterInfoFormType } from 'types/types';

import WaiterInfoUi from './WaiterInfoUi';

export const WaiterInfo = ({ token }: { token: string }) => {
  const [storeEmployees, setStoreEmployees] = useState<IEmployee[] | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [createForm] = Form.useForm<TWaiterInfoFormType>();
  const [editForm] = Form.useForm<TWaiterInfoFormType>();
  const editWaiterId = useRef<string>('');

  const isLoading = useAppSelector(getWaitersLoading);
  const stores = useAppSelector(getEvotorStores);
  const employees = useAppSelector(getEvotorEmployees);
  const error = useAppSelector(getWaiterError);
  const deleteMessage = useAppSelector(getWaiterDeleteMessage);
  const waitersData = useAppSelector(waitersSelector);
  const updateMarker = useAppSelector(getUpdateMarker);
  const createMarker = useAppSelector(getCreateMarker);
  const uiListData = getWaiterData(waitersData, stores);

  const actions = useActionCreators({
    createWaiter,
    deleteWaiter,
    getWaiters,
    editWaiter,
    resetErrors,
    resetIsCreated,
    resetDeleteMessage,
    resetIsUpdated,
  });

  const deletehandler = (id: string) => {
    actions.deleteWaiter({
      token,
      waiterId: id,
    });
  };

  const edithandler = (id: string) => {
    setEditMode(true);
    editWaiterId.current = id;
    const currentWaiter = waitersData?.find((item) => item.waiterId === id);
    if (currentWaiter) {
      editForm.setFields([
        { name: 'storeId', value: currentWaiter.shopId, errors: [] },
        { name: 'waiterId', value: currentWaiter.name, errors: [] },
        { name: 'tipsLink', value: currentWaiter.tipsLink, errors: [] },
      ]);
    }
  };

  const storeSelectHandler = (id: string) => {
    // фильтрация официантов по магазину
    const storeEmployees = getEmpolyeesByStore(employees, id);
    // исключение подключенных официантов
    const usefulEmployees = getUnconnectedList(storeEmployees, waitersData);
    setStoreEmployees(usefulEmployees);
    // очистка зависимых полей, при условии, что зависимое поле заполнено
    if (editMode ? editForm.getFieldValue('waiterId') : createForm.getFieldValue('waiterId')) {
      clearDependentFields(createForm, editForm, editMode, 'waiterId');
    }
  };

  const resetWaiter = () => {
    actions.resetErrors();
    actions.resetDeleteMessage();
    actions.resetIsCreated();
    actions.resetIsUpdated();
  };

  const onCreateFinish = async (formData: TWaiterInfoFormType) => {
    const { storeId, waiterId, tipsLink } = formData;
    const currentWaiter = employees?.find((item) => item.id === waiterId);

    if (currentWaiter) {
      const body = {
        waiterId,
        tipsLink,
        shopId: storeId,
        name: currentWaiter?.name,
      };
      actions.createWaiter({ token, body });
    }
    createForm.setFields([
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'waiterId', value: undefined, errors: [] },
      { name: 'tipsLink', value: undefined, errors: [] },
    ]);
  };

  const onEditFinish = async (formData: TWaiterInfoFormType) => {
    const { tipsLink } = formData;
    const body = {
      tipsLink,
    };
    actions.editWaiter({ token, waiterId: editWaiterId.current, body });
    const clearInfo = [
      { name: 'storeId', value: undefined, errors: [] },
      { name: 'waiterId', value: undefined, errors: [] },
      { name: 'tipsLink', value: undefined, errors: [] },
    ];
    editForm.setFields(clearInfo);
    createForm.setFields(clearInfo);
    editWaiterId.current = '';
    setEditMode(false);
  };

  useEffect(() => {
    actions.getWaiters({ token, shopId: '' });
  }, []);

  useNotifications(
    !!deleteMessage,
    !!error?.delete,
    deleteMessage || 'Успешно удалено',
    error?.delete,
    resetWaiter,
  );
  useNotifications(
    !!createMarker,
    !!error?.create,
    'Операция прошла успешно',
    error?.create,
    resetWaiter,
  );
  useNotifications(
    !!updateMarker,
    !!error?.update,
    'Операция прошла успешно',
    error?.update,
    resetWaiter,
  );
  useNotifications(false, !!error?.get, '', error?.get, resetWaiter);

  return (
    <WaiterInfoUi
      createForm={createForm}
      editForm={editForm}
      storesData={getSelectListData(stores)}
      employees={getSelectListData(storeEmployees)}
      loading={isLoading}
      onCreateFinish={onCreateFinish}
      onEditFinish={onEditFinish}
      deletehandler={deletehandler}
      edithandler={edithandler}
      waitersData={uiListData}
      editMode={editMode}
      setEditMode={setEditMode}
      storeSelectHandler={storeSelectHandler}
    />
  );
};
