import React from 'react';
import { Button, Form, FormInstance, Select, Spin, Space, Table } from 'antd';

import type { TGetReportsResponse, TReportsForm, TSelectList } from 'types/types';

import { reportsColumns } from 'constant';

import ReportRangePicker from './ReportRangePicker';

import style from './SaleReportsUi.module.css';

type SaleReportsUiPropsType = {
  loading: boolean;
  form: FormInstance<TReportsForm>;
  storesData: TSelectList;
  tableData: TGetReportsResponse;
  onChangeRangePicker: (_: any, dateStrings: [string, string]) => void;
  downloadReportByExcel: () => void;
  onFinish: (values: TReportsForm) => void;
};

const SaleReportsUi = ({
  loading,
  form,
  storesData,
  onFinish,
  tableData,
  onChangeRangePicker,
  downloadReportByExcel,
}: SaleReportsUiPropsType) => (
  <Spin spinning={loading} tip="Loading" size="large">
    <Form form={form} layout="vertical" className={style.form} onFinish={onFinish}>
      <div className={style.wrapper}>
        <div className={style.fieldsWrapper}>
          <div className={style.inputs}>
            <Form.Item
              name="storeId"
              label="Магазин"
              rules={[{ required: true, message: 'Обязательное поле' }]}
              className={style.storeSelect}
            >
              <Select size="large" options={storesData} allowClear />
            </Form.Item>
            <Form.Item
              name="reportRange"
              label="Период отчёта"
              rules={[{ required: true, message: 'Обязательное поле' }]}
            >
              <ReportRangePicker onChangeRangePicker={onChangeRangePicker} />
            </Form.Item>
          </div>

          <Form.Item className={style.picker}>
            <Button size="large" type="primary" htmlType="submit">
              Сформировать
            </Button>
          </Form.Item>
        </div>
        <div className={style.reportTable}>
          <Table
            columns={reportsColumns}
            dataSource={tableData.data}
            scroll={{ y: 400 }}
            pagination={false}
            bordered
          />
          <div className={style.reportTableBottom}>
            <pre>{`Итого: ${tableData.total.toFixed(2)} руб.`}</pre>
            <Button size="large" type="primary" onClick={downloadReportByExcel}>
              Скачать
            </Button>
          </div>
        </div>
      </div>
    </Form>
  </Spin>
);

export default SaleReportsUi;
