import { AxiosResponse } from 'axios';

import {
  TDeleteTablesRequest,
  TDeleteTablesResponse,
  TEditTablesRequest,
  TEditTablesResponse,
  TGetTablesRequest,
  TGetTablesResponse,
  TPostBody,
  TPostTablesRequest,
  TPostTablesResponse,
} from 'types/types';
import _api from '../http';

class TableService {
  static point = '/api/v1/lk/merchant/table';

  static async getTables(
    token: string,
    shopId: string,
  ): Promise<AxiosResponse<TGetTablesResponse, TGetTablesRequest>> {
    return _api.get<TGetTablesResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { shopId },
    });
  }

  static async createTable(
    token: string,
    body: TPostBody,
  ): Promise<AxiosResponse<TPostTablesResponse, TPostTablesRequest>> {
    return _api.post<TPostTablesResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async editTable(
    token: string,
    tableId: string,
    body: TPostBody,
  ): Promise<AxiosResponse<TEditTablesResponse, TEditTablesRequest>> {
    return _api.put<TEditTablesResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { tableId },
    });
  }

  static async deleteTable(
    token: string,
    tableId: string,
  ): Promise<AxiosResponse<TDeleteTablesResponse, TDeleteTablesRequest>> {
    return _api.delete<TDeleteTablesResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { tableId },
    });
  }
}

export default TableService;
