export const enum EmployeeRoles {
  admin = 'ADMIN',
  cashier = 'CASHIER',
  manual = 'MANUAL',
}

export enum ETabs {
  CASH_REGISTERS = 'cash_registers',
  TABLES = 'tables',
  WAITERS = 'waiters',
  SALE_REPORTS = 'sale_reports',
}
