import { AxiosResponse } from 'axios';

import {
  TGetPosRequest,
  TGetPosResponse,
  TPostPosResponse,
  TPostPosRequest,
  TPosBody,
  TDeletePosResponse,
  TDeletePosRequest,
  TEditPosRequest,
  TPosEditeBody,
  TEditPosResponse,
} from 'types/types';

import _api from '../http';

class PosService {
  static point = '/api/v1/lk/merchant/pos';

  static async getPos(token: string): Promise<AxiosResponse<TGetPosResponse, TGetPosRequest>> {
    return _api.get<TGetPosResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async createPos(
    token: string,
    body: TPosBody,
  ): Promise<AxiosResponse<TPostPosResponse, TPostPosRequest>> {
    return _api.post<TPostPosResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async editPos(
    token: string,
    posId: string,
    body: TPosEditeBody,
  ): Promise<AxiosResponse<TEditPosResponse, TEditPosRequest>> {
    return _api.put<TEditPosResponse>(this.point, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { posId },
    });
  }

  static async deletePos(
    token: string,
    posId: string,
  ): Promise<AxiosResponse<TDeletePosResponse, TDeletePosRequest>> {
    return _api.delete<TDeletePosResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { posId },
    });
  }
}

export default PosService;
