import type { AxiosResponse } from 'axios';

import type {
  TGetDownLoadReportsRequest,
  TGetDownLoadReportsResponse,
  TGetReportsRequest,
  TGetReportsResponse,
} from 'types/types';

import _api from '../http';

class ReportsService {
  static point = '/api/v1/lk/statistic';

  static async getReport(
    token: string,
    shopId: string,
    fromDate: Date,
    toDate: Date,
  ): Promise<AxiosResponse<TGetReportsResponse, TGetReportsRequest>> {
    return _api.get<TGetReportsResponse>(this.point, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { shopId, fromDate, toDate },
    });
  }

  static async downloadReport(
    token: string,
    shopId: string,
    fromDate: Date,
    toDate: Date,
  ): Promise<AxiosResponse<TGetDownLoadReportsResponse, TGetDownLoadReportsRequest>> {
    return _api.get<TGetDownLoadReportsResponse>(`${this.point}/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { shopId, fromDate, toDate },
      responseType: 'arraybuffer',
    });
  }
}

export default ReportsService;
